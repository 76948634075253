import React, { useState, useEffect, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";

import { CardPair } from "../components/RankingCard";
import { Title } from "../components/Text";
import { Dots } from "../components/index";
import { useStore } from "../hooks/useStore";
import { useModalStore } from "../hooks/useModalStore";
import { NUM_RATINGS } from "@last-haven/shared/constants";
import { Loading } from "../components/Loading";
import { AfterRankingModal } from "../containers/AfterRankingModal";

const forTranslation = (s) =>
  s.toLowerCase().replace(/ /g, "-").replace(/\//g, "-");

export function Ranking() {
  const processedPairsRef = useRef(new Set());
  const loading = useStore((state) => state.loading);
  const rankingsSessionId = useStore((state) => state.rankingsSessionId);
  const resetRankings = useStore((state) => state.resetRankings);
  const cardPairs = useStore((state) => state.rankings);
  const selectCard = useStore((state) => state.selectRankingFromPair);
  const updateRankingScores = useStore((state) => state.updateRankingScores);
  const setTimeStamp = useStore(
    (state) => state.setRankingsPresentationTimeStamp
  );
  const openAfterRankingModal = useModalStore(
    (state) => state.openAfterRankingModal
  );

  const completeAndNext = useCallback(() => {
    openAfterRankingModal();
  }, [openAfterRankingModal]);

  const [pairNumber, setPairNumber] = useState(
    () => cardPairs.findIndex((pair) => !pair.some((c) => c.chosen)) || 0
  );

  const resetPairs = useCallback(() => {
    processedPairsRef.current = new Set();
    resetRankings();
    setPairNumber(0);
  }, [setPairNumber, resetRankings]);

  useEffect(() => {
    if (pairNumber === -1 || pairNumber >= NUM_RATINGS) {
      completeAndNext();
    }
  }, [pairNumber, completeAndNext]);

  const { t } = useTranslation("ranking");

  const onSelect = (choice) => {
    if (
      processedPairsRef.current.has(pairNumber) ||
      cardPairs[pairNumber].some((c) => c.chosen)
    ) {
      return;
    }
    processedPairsRef.current.add(pairNumber);
    selectCard({ chosen: choice, scenarioOrder: pairNumber });
    const pair = cardPairs[pairNumber];
    const winner = pair[choice].species || pair[choice].landUse;
    const loser = pair[1 - choice].species || pair[1 - choice].landUse;
    updateRankingScores({ winner, loser });
    if (pairNumber < NUM_RATINGS - 1) {
      setPairNumber((n) => n + 1);
    } else {
      completeAndNext();
    }
  };

  useEffect(() => {
    if (!loading && cardPairs?.[pairNumber] && rankingsSessionId) {
      setTimeStamp({
        scenarioOrder: pairNumber,
        presentationTimeStamp: Date.now(),
      });
    }
  }, [pairNumber, setTimeStamp, loading, cardPairs, rankingsSessionId]);

  const isOrHas = t(`${cardPairs?.[pairNumber]?.[0]?.species ? "is" : "has"}`);
  const feature =
    cardPairs?.[pairNumber] &&
    t(`${forTranslation(cardPairs?.[pairNumber]?.[0]?.feature)}`);
  const titleText = t("title", { isOrHas });
  const showLoading = loading || !cardPairs?.[pairNumber] || !rankingsSessionId;
  return (
    <>
      <AfterRankingModal resetPairs={resetPairs} />
      {showLoading ? (
        <Loading />
      ) : (
        <>
          <MobileRankingsGame
            title={titleText}
            feature={feature}
            pair={cardPairs[pairNumber]}
            pairNumber={pairNumber}
            onSelect={onSelect}
            t={t}
          />
          <DesktopRankingsGame
            title={titleText}
            feature={feature}
            pair={cardPairs[pairNumber]}
            pairNumber={pairNumber}
            onSelect={onSelect}
            t={t}
          />
        </>
      )}
    </>
  );
}

const MobileRankingsGame = ({
  title,
  feature,
  pair,
  pairNumber,
  onSelect,
  deselect,
  onNext,
  t,
}) => {
  return (
    <div className="sm:hidden max-w-343px m-auto mb-6">
      <div className="font-700 text-4.5 leading-6 text-center mb-4">
        {title}{" "}
        <span className="text-lastHaven-primary-main underline">{feature}</span>
        ?
      </div>

      <CardPair pair={pair} onSelect={onSelect} />
      <Dots count={NUM_RATINGS} active={pairNumber} onClick={onNext} />
    </div>
  );
};

const DesktopRankingsGame = ({
  title,
  feature,
  pair,
  pairNumber,
  onSelect,
  t,
}) => {
  return (
    <div className="hidden sm:block sm:w-868px m-auto">
      <h1 className="font-sans pb-4.5 text-6 leading-8 font-700 leading-32.68px text-center">
        {title}{" "}
        <span className="text-lastHaven-primary-main underline">{feature}</span>
        ?
      </h1>

      <CardPair pair={pair} onSelect={onSelect} />
      <Dots count={NUM_RATINGS} active={pairNumber} />
    </div>
  );
};
