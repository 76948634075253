import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLoaderData } from "react-router-dom";

import { Subtitle, BoldParagraph, Paragraph } from "../components/Text";
import { Button } from "../components/Button";
import { useStore } from "../hooks/useStore";
import {
  NUM_CONSENT_PARAGRAPHS,
  NUM_DILEMMAS,
} from "@last-haven/shared/constants";
import "../styles/colors.css";

const ConsentBlock = ({ counter, heading, text }) => (
  <div className="pt-4">
    <span className="font-700">
      {counter}. <BoldParagraph text={heading} />
    </span>
    <div className="pt-2">
      <Paragraph text={text} />
    </div>
  </div>
);

export function Consent() {
  const { nextPath } = useLoaderData();
  const { t } = useTranslation("consent");
  const [accepted, setAccepted] = useState(false);
  const giveConsent = useStore((state) => state.giveConsent);
  const navigate = useNavigate();

  // TODO: send them somewhere if they cancel
  const onCancel = () => navigate("/");
  const onSubmit = () => {
    giveConsent();
    navigate(nextPath);
  };
  const hasSubtitle = t("subtitle") !== "subtitle";
  return (
    // TODO: md:pt-8 isnt working and padding is set by the small screen pad size
    <div className="py-8 md:py-8 px-4 sm:px-110px m-auto max-w-1090px">
      <div className="pb-2">
        <div className="text-6 font-700 leading-8 text-left">{t("title")}</div>
        <br />
        <div
          className="font-sans text-4 leading-6 font-400 leading-24px text-left"
          dangerouslySetInnerHTML={{ __html: t("subtitle") }}
        />
      </div>
      {Array(NUM_CONSENT_PARAGRAPHS)
        .fill(0)
        .map((_, ii) => (
          <ConsentBlock
            key={ii}
            counter={ii + 1}
            heading={t(`heading-${ii + 1}`)}
            text={t(`paragraph-${ii + 1}`, { numDilemmas: NUM_DILEMMAS })}
          />
        ))}
      <div className="pt-6 md:pt-6">
        <div className="flex items-center">
          <input
            type="checkbox"
            className="mr-2 leading-tight h-6 w-6 accent-lastHaven-primary-main checked:color-lastHaven-gray-100"
            style={{
              // only large radius works via unocss
              // tried rounded-sm, rounded-4px, ...etc.
              borderRadius: "4px",
              // can't be set by the border className as expected
              // attemped border-lastHaven-gray-300, border-color-lastHaven-gray-300, color-lastHaven-gray-300
              borderColor: "var(--gray-300)",
            }}
            checked={accepted}
            onChange={() => setAccepted((a) => !a)}
          />
          <label onClick={() => setAccepted((a) => !a)} className="block mb-0">
            {t("tickbox-label")}
          </label>
        </div>
        <div className="pt-2 text-lastHaven-gray-600 text-sm leading-19px ml-2">
          {t("tickbox-expanded-label")}
        </div>
      </div>
      <div className="pt-6 md:pt-6 flex justify-end">
        <Button
          secondary
          text={t("secondary-button-label")}
          onClick={onCancel}
          disabled={!accepted}
        />
        <div className="ml-4" />
        <Button
          text={t("primary-button-label")}
          onClick={onSubmit}
          disabled={!accepted}
        />
      </div>
    </div>
  );
}
