import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Modal } from "../components/Modal";
import { SocialShareLinks } from "./SocialShare";
import { useStore } from "../hooks/useStore";
import { useModalStore } from "../hooks/useModalStore";
import { Button } from "../components/Button";
import { speciesMap, buildingsMap } from "@last-haven/shared/constants";

// enableBlockerRef is necessary to turn off the blocker when navigation is allowed
// state did not work in this role because navigation is synchronous but state updates asynchronously
export function AfterRankingModal({ resetPairs = () => {} }) {
  const { t } = useTranslation("after-ranking");
  const [destination, setDestination] = useState("/");
  const navigate = useNavigate();
  const rankingScores = useStore((state) => state.rankingScores);
  const rankingsCategory = useStore((state) => state.rankingsCategory);
  const rankingsFeature = useStore((state) => state.rankingsFeature);
  const afterRankingModalIsOpen = useModalStore(
    (state) => state.afterRankingModalIsOpen
  );
  const closeModal = useModalStore((state) => state.closeAfterRankingModal);
  const map = rankingsCategory === "species" ? speciesMap : buildingsMap;
  // sort ranking scores {key: value} by score in descending order
  console.log("rankingsFeature", rankingsFeature);
  console.log("rankingScores", rankingScores[rankingsFeature]);
  const sortedScores = Object.entries(
    rankingScores[rankingsFeature] || {}
  ).sort((a, b) => b[1] - a[1]);

  const closeHandler = () => {
    resetPairs();
    closeModal();
    navigate(destination);
  };

  const cancelHandler = () => {
    setDestination("/");
    closeModal();
  };

  const playHandler = () => {
    setDestination("/ranking");
    closeModal();
  };
  return (
    <Modal
      open={afterRankingModalIsOpen}
      onClose={closeHandler}
      className="overflow-y-scroll max-h-100svh"
    >
      <div className="max-sm:w-screen sm:w-full">
        <div className="w-357px sm:max-w-870px sm:w-870px flex flex-col justify-center items-center font-sans mx-auto sm:mx-10 sm:my-8">
          <div className="flex flex-col justify-center items-center">
            <div className="text-6 leading-8 font-700 mb-24px">
              {t("title", { feature: rankingsFeature })}:
            </div>
            <div className="h-576px w-full flex flex-col justify-center items-center overflow-auto">
              {sortedScores.map((score, index) => (
                <div
                  key={`ranking-score-${index}`}
                  className="flex items-center h-96px w-400px"
                >
                  <img
                    className="h-auto min-h-76px max-h-86px w-auto max-w-166px"
                    src={`${map[score?.[0]]?.image}`}
                    alt={score?.[0]}
                  />
                  <div className="text-4.5 leading-24.5px ml-4">{`${
                    index + 1
                  }. ${score?.[0]}`}</div>
                </div>
              ))}
            </div>
            <div className="flex justify-center items-center h-57px w-full my-48px">
              <Button
                secondary
                className="mr-24px"
                text={t("no-thanks")}
                onClick={cancelHandler}
              />
              <Button
                className="ml-24px"
                text={t("play-again")}
                onClick={playHandler}
              />
            </div>
            <div className="flex flex-col justify-between items-center w-full h-75px">
              <div className="text-5">{t("footer")}</div>
              <div>
                <SocialShareLinks />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
