import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Modal } from "../components/Modal";
import { useStore } from "../hooks/useStore";
import { Button } from "../components/Button";

// enableBlockerRef is necessary to turn off the blocker when navigation is allowed
// state did not work in this role because navigation is synchronous but state updates asynchronously
export function BeforeRankingModal({ enableBlockerRef }) {
  const { t } = useTranslation("before-ranking");
  const navigate = useNavigate();
  const rankingsCategory = useStore((state) => state.rankingsCategory);
  const setRankingCategory = useStore((state) => state.setRankingCategory);

  const selectHandler = (category) => {
    setRankingCategory(category);
    navigate("/ranking");
  };

  const cancelHandler = () => {};
  return (
    <Modal open={!rankingsCategory} onClose={cancelHandler}>
      <div className="h-full max-sm:w-screen sm:w-full overflow-y-auto">
        <div className="w-357px max-sm:h-84svh sm:max-w-568px sm:w-568px flex flex-col justify-center items-center font-sans mx-auto sm:mx-10 sm:my-8">
          <div className="flex flex-col justify-center items-center max-sm:flex-grow ">
            <div className="text-6 leading-8 font-700">{t("title")}</div>
            <div className="flex flex-col w-full mt-6 max-sm:h-130px">
              <Button
                className="w-full sm:w-272px h-57px"
                text={t("species")}
                onClick={() => selectHandler("species")}
              />
              <Button
                className="w-full sm:w-272px h-57px my-4"
                text={t("buildings")}
                onClick={() => selectHandler("buildings")}
              />
              <Button
                className="w-full sm:w-272px h-57px"
                text={t("either")}
                onClick={() =>
                  selectHandler(Math.random() < 0.5 ? "species" : "buildings")
                }
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
