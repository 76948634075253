import React, { useState, useEffect, useCallback, useRef } from "react";
import { useLoaderData } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { CardPair } from "../components/Card";
import { Title } from "../components/Text";
import { Dots } from "../components/index";
import { useStore } from "../hooks/useStore";
import { useModalStore } from "../hooks/useModalStore";
import { NUM_DILEMMAS, regionsMap } from "@last-haven/shared/constants";
import { Loading } from "../components/Loading";
import { BeforeLeaveModal } from "./BeforeLeaveModal.jsx";
import { PostGameModal } from "./PostGameModal.jsx";

const forTranslation = (s) =>
  s.toLowerCase().replace(/ /g, "-").replace(/\//g, "-");

export function Game() {
  const { nextPath } = useLoaderData();
  // use a ref here for sychronous unblocking on completion
  const blockNavigationRef = useRef(true);
  const processedPairsRef = useRef(new Set());
  const loading = useStore((state) => state.loading);
  const cardPairs = useStore((state) => state.dilemmas);
  const selectCard = useStore((state) => state.selectCardFromPair);
  const openAfterGameModal = useModalStore((state) => state.openAfterGameModal);

  const completeAndNext = useCallback(() => {
    blockNavigationRef.current = false;
    openAfterGameModal();
  }, [openAfterGameModal]);

  const [pairNumber, setPairNumber] = useState(
    () => cardPairs.findIndex((pair) => !pair.some((c) => c.chosen)) || 0
  );

  useEffect(() => {
    if (pairNumber === -1 || pairNumber >= NUM_DILEMMAS) {
      completeAndNext();
    }
  }, [pairNumber, completeAndNext]);

  const { t } = useTranslation("game");

  const onSelect = (choice) => {
    if (
      processedPairsRef.current.has(pairNumber) ||
      cardPairs[pairNumber].some((c) => c.chosen)
    ) {
      return;
    }
    processedPairsRef.current.add(pairNumber);
    selectCard({ chosen: choice, scenarioOrder: pairNumber });

    if (pairNumber < NUM_DILEMMAS - 1) {
      setPairNumber((n) => n + 1);
    } else {
      completeAndNext();
    }
  };

  if (loading || !cardPairs?.[pairNumber]) return <Loading />;
  return (
    <>
      <BeforeLeaveModal enableBlockerRef={blockNavigationRef} />
      <PostGameModal nextPath={nextPath} />
      <MobileGame
        pair={cardPairs[pairNumber]}
        pairNumber={pairNumber}
        onSelect={onSelect}
        t={t}
      />
      <DesktopGame
        pair={cardPairs[pairNumber]}
        pairNumber={pairNumber}
        onSelect={onSelect}
        t={t}
      />
    </>
  );
}

const MobileGame = ({ pair, pairNumber, onSelect, deselect, onNext, t }) => {
  const setPresentationTimeStamp = useStore(
    (state) => state.setPresentationTimeStamp
  );
  const setTaskDescriptionShown = useStore(
    (state) => state.setTaskDescriptionShown
  );

  useEffect(() => {
    setPresentationTimeStamp({
      scenarioOrder: pairNumber,
      presentationTimeStamp: Date.now(),
    });
  }, [pairNumber, setPresentationTimeStamp]);

  const regionImage = regionsMap[pair[0].region]?.image;
  const regionLabel = t(`region.${forTranslation(pair[0].region)}`);
  return (
    <div className="sm:hidden max-w-343px m-auto mb-6">
      <div className="font-700 text-4.5 leading-6 text-center">
        {t("title")}
      </div>
      <div className="flex flex-col justify-center items-center w-full py-4">
        <img src={regionImage} alt={regionLabel} className="w-220px h-112px" />
        <div className="text-3 leading-4 text-center">{regionLabel}</div>
      </div>
      <CardPair
        pair={pair}
        onSelect={onSelect}
        onShowDescription={() =>
          setTaskDescriptionShown({ scenarioOrder: pairNumber })
        }
      />
      <Dots count={NUM_DILEMMAS} active={pairNumber} onClick={onNext} />
    </div>
  );
};

const DesktopGame = ({ pair, pairNumber, onSelect, t }) => {
  const setPresentationTimeStamp = useStore(
    (state) => state.setPresentationTimeStamp
  );
  const setTaskDescriptionShown = useStore(
    (state) => state.setTaskDescriptionShown
  );

  useEffect(() => {
    setPresentationTimeStamp({
      scenarioOrder: pairNumber,
      presentationTimeStamp: Date.now(),
    });
  }, [pairNumber, setPresentationTimeStamp]);

  const regionImage = regionsMap[pair[0].region]?.image;
  const regionLabel = t(`region.${forTranslation(pair[0].region)}`);
  return (
    <div className="hidden sm:block sm:w-868px m-auto">
      <Title text={t("title")} />
      <div className="flex flex-col justify-center items-center w-full">
        <img src={regionImage} alt={regionLabel} className="w-220px h-112px" />
        <div className="text-3 leading-4 text-center">{regionLabel}</div>
      </div>

      <CardPair
        pair={pair}
        onSelect={onSelect}
        onShowDescription={() =>
          setTaskDescriptionShown({ scenarioOrder: pairNumber })
        }
      />
      <Dots count={NUM_DILEMMAS} active={pairNumber} />
    </div>
  );
};
