import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { speciesMap } from "@last-haven/shared/constants";
import { shuffleArray } from "@last-haven/shared/randomisation";
import { useStore } from "../hooks/useStore";
import { useModalStore } from "../hooks/useModalStore";
import { Button } from "../components/Button";
import { Modal } from "../components/Modal";
import { SpeciesSelectionTypes } from "@last-haven/shared/constants.js";

const forTranslation = (s) =>
  s.toLowerCase().replace(/ /g, "-").replace(/\//g, "-");

const TickBox = React.memo(({ selected, className }) => (
  <img
    src={`/images/${selected ? "checked" : "unchecked"}-selected.svg`}
    className={{ ...className }}
    alt={selected ? "checked" : "unchecked"}
  />
));

const SpeciesCard = React.memo(({ label, image, selected, onClick, t }) => {
  const translatedLabel = t(`species.${forTranslation(label)}.label`);
  const clickHandler = useCallback(() => onClick(label), [onClick, label]);
  return (
    <div
      className={`relative flex flex-col rounded-2 w-168px h-129px m-4px sm:m-8px outline-solid ${
        selected
          ? "outline-lastHaven-primary-main outline-2"
          : "outline-1px outline-lastHaven-gray-100"
      }`}
      onClick={clickHandler}
    >
      <div className="absolute top-0 left-0">
        <TickBox selected={selected} className="w-24px h-24px" />
      </div>
      <img
        src={image}
        className="absolute top-8px w-168px h-87px"
        alt={translatedLabel}
      />
      <div className="absolute top-103px w-full text-center sm:(text-3.5 h-19px mb-8px)">
        {translatedLabel}
      </div>
    </div>
  );
});

export function PostGameModal({ nextPath }) {
  const [selected, setSelected] = useState(new Set());
  const [pageNumber, setPageNumber] = useState(0);
  const navigate = useNavigate();
  const { t } = useTranslation("post-game");
  const { t: tCard } = useTranslation("card");
  const dilemmas = useStore((state) => state.dilemmas);
  const createSpeciesSelection = useStore(
    (state) => state.createSpeciesSelection
  );
  const afterGameModalIsOpen = useModalStore(
    (state) => state.afterGameModalIsOpen
  );
  const closeAfterGameModal = useModalStore(
    (state) => state.closeAfterGameModal
  );
  const species = useMemo(
    () =>
      shuffleArray(
        dilemmas.map(
          (dilemma) => dilemma[0].species?.name || dilemma[1].species?.name
        )
      ),
    [dilemmas]
  );

  const handleClick = useCallback(
    (species) => {
      setSelected((prevSelected) => {
        const newSelected = new Set(prevSelected);
        if (newSelected.has(species)) {
          newSelected.delete(species);
        } else {
          newSelected.add(species);
        }
        return newSelected;
      });
    },
    [setSelected]
  );

  return (
    <Modal
      open={afterGameModalIsOpen}
      onClose={() => {
        closeAfterGameModal();
        navigate(nextPath);
      }}
      className="overflow-y-scroll max-h-[100svh]"
    >
      <div className="flex flex-col justify-center items-center w-395px h-973px sm:(w-1090px h-752px)">
        <div className="w-343px h-973px sm:(w-962px)">
          {pageNumber === 0 ? (
            <div className="flex flex-col justify-center items-center w-full">
              <h1 className="font-inter font-700 text-6 leading-8 sm:(text-8 leading-9.5 mb-2) text-center">
                {t("page-1-title")}
              </h1>
              <div className="font-sans font-700 text-4 leading-6 sm:(text-4.5 leading-8) text-center">
                {t("page-1-subtitle")}
              </div>
              <div className="flex flex-wrap w-352px mt-7 sm:(w-736px mt-8) justify-center items-center">
                {species.map((species, index) => {
                  return (
                    <SpeciesCard
                      key={species}
                      {...speciesMap[species]}
                      selected={selected.has(species)}
                      onClick={handleClick}
                      t={tCard}
                    />
                  );
                })}
              </div>
              <div className="flex justify-start items-center w-344px h-32px mt-1 sm:(w-736px mt-2)">
                <div className="sm:(mx-8px)">
                  <TickBox selected={selected.size < 1} />
                </div>
                <div className="font-sans text-3.5 leading-19px text-center">
                  {t("none-of-the-above")}
                </div>
              </div>
              <Button
                text={t("page-1-submit-button")}
                className="mt-4 mb-8 sm:(w-131px h-57px my-8 text-4.5) font-700"
                onClick={() => {
                  createSpeciesSelection({
                    selected,
                    shown: species,
                    type: SpeciesSelectionTypes.FAMILIARITY,
                  });
                  setPageNumber(1);
                  setSelected(new Set());
                }}
              />
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center w-full">
              <h1 className="font-inter font-700 text-6 leading-8 sm:(text-8 leading-9.5 mb-2) text-center">
                {t("page-2-title")}
              </h1>
              <div className="font-sans font-700 text-4 leading-6 sm:(text-4.5 leading-8) text-center">
                {t("page-2-subtitle")}
              </div>
              <div className="flex flex-wrap w-352px mt-7 sm:(w-736px mt-8) justify-center items-center">
                {species.map((species, index) => {
                  return (
                    <SpeciesCard
                      key={species}
                      {...speciesMap[species]}
                      selected={selected.has(species)}
                      onClick={() => setSelected(new Set([species]))}
                      t={tCard}
                    />
                  );
                })}
              </div>
              <div className="flex justify-center items-center bg-lastHaven-gray-50 rounded-2 w-344px h-108px mt-1 sm:(w-full h-51px mt-4)">
                <div className="font-sans text-3.5 leading-19px text-center">
                  {t("donation-description")}
                </div>
              </div>
              <Button
                text={t("page-2-submit-button")}
                className="mt-4 mb-8 sm:( h-57px my-8 text-4.5) font-700"
                disabled={selected.size < 1}
                onClick={() => {
                  createSpeciesSelection({
                    selected,
                    shown: species,
                    type: SpeciesSelectionTypes.DONATION,
                  });
                  closeAfterGameModal();
                  setSelected(new Set());
                }}
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
